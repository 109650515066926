import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public userEmail: string | null = null;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    /* this.authService.userEmail$.subscribe(email => {
      this.userEmail = email;
    }); */
    this.userEmail = sessionStorage.getItem('email')
  }
  logout(): void {
    this.authService.signOut();
    this.router.navigate(['/login'])
    
  }
}
