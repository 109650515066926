import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-operation-ko',
  templateUrl: './modal-operation-ko.component.html',
  styleUrl: './modal-operation-ko.component.scss'
})
export class ModalOperationKoComponent {
  constructor(public dialogRef: MatDialogRef<ModalOperationKoComponent>) {}
  closeModal(): void {
    this.dialogRef.close();
  }
}
