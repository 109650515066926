import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
import { ModalCancelOperationComponent } from './components/modals/modal-cancel-operation/modal-cancel-operation.component';
import { ModalOperationOkComponent } from './components/modals/modal-operation-ok/modal-operation-ok.component';
import { ModalPersonalDataComponent } from './components/modals/modal-personal-data/modal-personal-data.component';
import { HttpClientModule } from '@angular/common/http';
import { ModalOperationKoComponent } from './components/modals/modal-operation-ko/modal-operation-ko.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ModalCancelOperationComponent,
    ModalOperationOkComponent,
    ModalPersonalDataComponent,
    ModalOperationKoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    NgxSkeletonLoaderModule
  
  ],
  exports: [
    MaterialModule,
    HeaderComponent,
    FooterComponent,
    ReactiveFormsModule,
    ModalCancelOperationComponent,
    ModalOperationOkComponent,
    ModalPersonalDataComponent,
    NgxSkeletonLoaderModule
  ], 
  providers: [
    CurrencyPipe,
    {provide: LOCALE_ID, useValue: 'es-ES' },
  ]
})
export class SharedModule { }
