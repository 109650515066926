<div class="fr-modal-align">
  <div class="title">
    <img class="fr-cancel-operation-img" src="../../../../../assets/svg/cancelProcessSvg.svg">
    <h2 class="fr-modal-title">CANCELAR PROCESO</h2>
  </div>
  <p class="fr-modal-content">Si cancelas ahora, perderás todos los datos ingresados hasta el momento</p>
  <div class="fr-align-buttons">
  <button (click)="closeModal()" class="stroked-gold-button">CONTINUAR CON EL PROCESO</button>
  <button (click)="closeModal()" class="gold-button" routerLink="/operaciones">CANCELAR DEFINITIVAMENTE</button>
</div>
</div>