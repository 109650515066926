
import { LoginService } from './login/login.service';
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, of, Subscription, tap } from "rxjs";
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable()
export class AuthService implements OnDestroy {
  token$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private logout: Subscription = new Subscription;

  constructor(@Inject(JwtHelperService) private jwtHelper: JwtHelperService, private loginService: LoginService) {
  }

  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token!);
  }

  public signIn(username: string, password: string): Observable<any> {
    const req = {
      username: username, 
      password: password,
    
    };
    
      return this.loginService.login(req)
        .pipe(
          tap(res => {
            sessionStorage.setItem('token', res.accessToken!)
            this.setTokenSessionStorage();
            
          }) 
          
        )

    }
 
  getTokenSessionStorage(): Observable<boolean> { 
    return this.token$.asObservable();
  }

  setTokenSessionStorage() {
    this.token$.next(this.checkToken());
  }
  checkToken() { 
    if(sessionStorage.getItem('token')) {
      return true
    } else {
      return false
    }
  }

  public signOut() {
    sessionStorage.clear();
    this.setTokenSessionStorage();
  }

  public token() {
    return sessionStorage.getItem('token')
  }

  ngOnDestroy() { 
    this.logout.unsubscribe();
  }
}