import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-operation-ok',
  templateUrl: './modal-operation-ok.component.html',
  styleUrl: './modal-operation-ok.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalOperationOkComponent { 
  constructor(public dialogRef: MatDialogRef<ModalOperationOkComponent>) {}

  closeModal(): void {
    this.dialogRef.close();
  }
}
