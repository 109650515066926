import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-operation',
  templateUrl: './modal-cancel-operation.component.html',
  styleUrl: './modal-cancel-operation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCancelOperationComponent { 

  constructor(public dialogRef: MatDialogRef<ModalCancelOperationComponent>) {}

  closeModal(): void {
    this.dialogRef.close();
  }
}
