import { Injectable } from '@angular/core';
import { LoginRequest, LoginResponse } from '../../models/login';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http:HttpClient) {

  }

  login(req: LoginRequest): Observable<LoginResponse> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'});
      
        return this.http.post<LoginResponse>(`${environment.baseUrl}/login`, req,  {
          headers: headers,
        })
   }
}