<div class="fr-modal">
  <h2 class="fr-modal-title">Tratamiento de Datos Personales</h2>
    <div class="content">
      <p class="fr-modal-content">
        <span>DENARIUS FINANCE, S.A., </span>como responsable del tratamiento,
        tratará sus datos con el fin de responder a su solicitud siendo la base
        legítima su consentimiento.
      </p>

      <p>
        Sus datos serán facilitados a encargados de tratamiento para cumplir con
        la finalidad descrita y/o en los casos en que existan obligaciones
        legales. Podrá ejercer los derechos que le asisten dirigiéndose a:
        <span class="fr-shine">clientes&#64;denariusfinance.com</span>
      </p>

      <p>
        Contacto DPD: <span class="fr-shine">dpo&#64;ingadeconnect.es</span>
      </p>

      <p>
        Puede tener acceso a información adicional sobre cómo tratamos sus datos <a href="https://denarius.es/politica-de-privacidad">aquí.</a>
      </p>
    </div>
    <div class="fr-align-buttons">
      <button (click)="closeModal()" class="gold-button">CERRAR</button>
    </div>
</div>
