import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-personal-data',
  templateUrl: './modal-personal-data.component.html',
  styleUrl: './modal-personal-data.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalPersonalDataComponent {
  constructor(public dialogRef: MatDialogRef<ModalPersonalDataComponent>) {}

  closeModal(): void {
    this.dialogRef.close();
  }
 }
